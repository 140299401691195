import Notify from 'quasar/src/plugins/Notify.js';

export default {
    notifySuccess(message) {
        Notify.create({
            message: message,
            color: 'green'
        })
    },
    notifyError(error) {
        Notify.create({
            message: error.response.data.message,
            color: 'red'
        })
    },
}