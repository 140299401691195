import axios from '../axios'

export default {
    async getQuantityUnitTypes() {
        return await axios.get('/QuantityUnitTypes')
    },
    async getQuantityUnitType(id) {
        return await axios.get(`/QuantityUnitTypes/Id/${id}`)
    },
    async postQuantityUnitType(quantityUnitType) {
        return await axios.post('/QuantityUnitTypes', quantityUnitType)
    },
    async putQuantityUnitType(quantityUnitType) {
        return await axios.put('/QuantityUnitTypes', quantityUnitType)
    },
    async deleteQuantityUnitType(id) {
        return await axios.delete(`/QuantityUnitTypes/Id/${id}`)
    },

    async getQuantityUsageTypes() {
        return await axios.get('/QuantityUsageTypes')
    },
    async getQuantityUsageType(id) {
        return await axios.get(`/QuantityUsageTypes/Id/${id}`)
    },
    async postQuantityUsageType(quantityUsageType) {
        return await axios.post('/QuantityUsageTypes', quantityUsageType)
    },
    async putQuantityUsageType(quantityUsageType) {
        return await axios.put('/QuantityUsageTypes', quantityUsageType)
    },
    async deleteQuantityUsageType(id) {
        return await axios.delete(`/QuantityUsageTypes/Id/${id}`)
    },

    async getQuantityItems() {
        return await axios.get('/QuantityItems')
    },
    async getQuantityItem(id) {
        return await axios.get(`/QuantityItems/Id/${id}`)
    },
    async postQuantityItem(quantityItem) {
        return await axios.post('/QuantityItems', quantityItem)
    },
    async deleteQuantityItem(id) {
        return await axios.delete(`/QuantityItems/Id/${id}`)
    },

    async getProjectQuantityItems(projectId) {
        return await axios.get(`/QuantityProjectItems/ProjectId/${projectId}`)
    },
    async postProjectQuantityItem(quantityProjectItem) {
        return await axios.post('/QuantityProjectItems', quantityProjectItem)
    },
    async putProjectQuantityItem(quantityProjectItem) {
        return await axios.put('/QuantityProjectItems', quantityProjectItem)
    },
    async getQuantityProjectItems(projectId) {
        return await axios.get(`/QuantityProjectItems/ProjectId/${projectId}`)
    },
    async deleteQuantityProjectItem(id) {
        return await axios.delete(`/QuantityProjectItems/Id/${id}`)
    },
    async getQuantityProjectItem(id) {
        return await axios.get(`/QuantityProjectItems/Id/${id}`)
    },
    async getQuantityItemUsages(projectItemId) {
        return await axios.get(`/QuantityItemsUsage/ProjectItemId/${projectItemId}`)
    },
    async getQuantityItemUsage(id) {
        return await axios.get(`/QuantityItemsUsage/Id/${id}`)
    },
    async postQuantityProjectItemUsage(quantityItemUsage) {
        return axios.post('/QuantityItemsUsage', quantityItemUsage)
    },
    async deleteQuantityItemUsage(id) {
        return axios.delete(`/QuantityItemsUsage/Id/${id}`)
    },

    async getVaccumTest(id) {
        return axios.get(`/VaccumTests/Id/${id}`)
    },
    async getVaccumTests(projectId) {
        return axios.get(`/VaccumTests/ProjectId/${projectId}`)
    },
    async postVaccumTest(vaccumTest) {
        return axios.post('/VaccumTests', vaccumTest)
    },
    async deleteVaccumTest(id) {
        return axios.delete(`/VaccumTests/Id/${id}`)
    },

    async getLapTest(id) {
        return axios.get(`/LowAirPressureTest/Id/${id}`)
    },
    async getLapTests(projectId) {
        return axios.get(`/LowAirPressureTest/ProjectId/${projectId}`)
    },
    async postLapTest(lapTest) {
        return axios.post('/LowAirPressureTest', lapTest)
    },
    async deleteLapTest(id) {
        return axios.delete(`/LowAirPressureTest/Id/${id}`)
    },

    async getReport(id) {
        return axios.get(`/Reports/Id/${id}`)
    },
    async getReports(projectId) {
        return axios.get(`/Reports/ProjectId/${projectId}`)
    },
    async postReport(report) {
        return axios.post('/Reports', report)
    },
    async deleteReport(id) {
        return axios.delete(`/Reports/Id/${id}`)
    },
    async getReportImages(reportId) {
      return axios.get(`/Reports/Images/ReportId/${reportId}`)
    },
    async getReportImage(reportImageId) {
        return axios.get(`/Reports/Images/ReportImageId/${reportImageId}`)
    },
    async deleteReportPhoto(reportImageId) {
        return axios.delete(`/Reports/Images/ReportImageId/${reportImageId}`)
    },

    async getNukeGaugeTest(id) {
        return axios.get(`/NukeGaugeTests/Id/${id}`)
    },
    async getNukeGaugeTests(projectId) {
        return axios.get(`/NukeGaugeTests/ProjectId/${projectId}`)
    },
    async postNukeGaugeTest(nukeGaugeTest) {
        return axios.post('/NukeGaugeTests', nukeGaugeTest)
    },
    async deleteNukeGaugeTest(id) {
        return axios.delete(`/NukeGaugeTests/Id/${id}`)
    },
}
